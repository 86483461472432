// @ts-ignore
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import FormForNotes from "../../Components/FormForNotes/FormForNotes";
import TipAndTaskComponent from "../../Components/TipAndTaskComponent/TipAndTaskComponent";

const TipsAndNotesTabs = (props: any) => {
  const { idSeller, idClient } = props;

  return (
    <div style={{ height: "calc(100% - 5rem)" }}>
      <Tabs>
        <TabList>
          <Tab>Consejos</Tab>
          <Tab>Notas</Tab>
        </TabList>
        <TabPanel>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h1
                style={{
                  fontFamily: "lis-title",
                  fontWeight: "bold",
                  fontSize: "25px",
                  marginTop: "65px",
                }}
              >
                Consejos
              </h1>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  overflow: "auto",
                }}
              >
                <TipAndTaskComponent
                  title={"Investigar RRSS"}
                  content={"Busca al cliente en las redes sociales."}
                ></TipAndTaskComponent>
                <TipAndTaskComponent
                  title={"Búsqueda de información"}
                  content={
                    "Investiga al cliente y toma notas que ayuden a la hora de realizar la visita."
                  }
                ></TipAndTaskComponent>
                <TipAndTaskComponent
                  title={"Escuchar la llamada de fábrica de visita"}
                  content={
                    "Recuerda escuchar las llamadas del cliente antes de empezar la visita."
                  }
                ></TipAndTaskComponent>
              </div>
            </div>
            <div
              style={{
                paddingTop: "1rem",
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h1
                style={{
                  fontFamily: "lis-title",
                  fontWeight: "bold",
                  fontSize: "25px",
                  marginBottom: "0px",
                }}
              >
                Tareas
              </h1>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  overflow: "auto",
                  paddingBottom: "1rem",
                }}
              >
                <TipAndTaskComponent
                  title={"Punto de venta"}
                  content={
                    "Comprueba que este ordenado el punto de venta para que la cita se desarrolle de la mejor forma posible."
                  }
                ></TipAndTaskComponent>
                <TipAndTaskComponent
                  title={"Maqueta fisica"}
                  content={
                    "Recuerda tener preparada la maqueta fisica para la visita."
                  }
                ></TipAndTaskComponent>
                <TipAndTaskComponent
                  title={"Showroom"}
                  content={"Recuerda tener preparado y recogido el showroom."}
                ></TipAndTaskComponent>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <FormForNotes
            idClient={idClient}
            idSeller={idSeller}
            where="appointment"
          ></FormForNotes>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default TipsAndNotesTabs;
