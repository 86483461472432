import { Card, Modal } from "@nextui-org/react";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
// @ts-ignore
import "react-tabs/style/react-tabs.css";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import AppointmentClientTabs from "../../Components/Appointment/AppointmentClientTabs";
import ClientInfo from "../../Components/Appointment/ClientInfo";
import StartTheAppointment from "../../Components/Appointment/StartTheAppointment";
import TipsAndNotesTabs from "../../Components/Appointment/TipsAndNotesTabs";
import ModalAppointment from "../../Components/ModalAppointment/ModalAppointment";
import ModalNotIntroduces from "../../Components/ModalNotIntroduces/ModalNotIntroduces";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import { getClientByAppointmentId } from "../../Service/AppointmentService/AppointmentService";

import { getUser } from "../../Utils/commonUtils";
import "./Appointment.css";

function Appointment(props: any) {
  const { id }: any = useParams();
  const user = getUser(); 
  const [client, isFetchingClient] = useFetch(getClientByAppointmentId, id);


  const isLoading = useIsLoading([
    isFetchingClient,
  ]);
  const [openModalStart, setopenModalStart] = useState(false);
  const [openModalNotIntroduced, setModalNotIntroduces] = useState(false);

  const idSeller = user.id;
  const [idClient, setIdClient] = useState("");

  useEffect(() => {
    setIdClient(client?.id);
  }, [client]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <Modal
        scroll
        width="600px"
        open={openModalStart || openModalNotIntroduced}
        preventClose
        onClose={() => {
          setopenModalStart(false);
          setModalNotIntroduces(false);
        }}
        closeButton
      >
        {openModalStart && (
          <ModalAppointment
            id={id}
            idClient={idClient}
            idSeller={idSeller}
          ></ModalAppointment>
        )}
        {openModalNotIntroduced && (
          <ModalNotIntroduces
            id_appoiment={id}
            where="appointment"
            setModalNotIntroduces={setModalNotIntroduces}
          ></ModalNotIntroduces>
        )}
      </Modal>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "30%",
            height: "100vh",
            padding: "1rem",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card
              css={{
                width: "100%",
                height: "100%",
                padding: "0rem",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <ClientInfo client={client}></ClientInfo>
              </div>
              <AppointmentClientTabs
                client={client}
                id={id}
              ></AppointmentClientTabs>
            </Card>
          </div>
        </div>
        <div
          style={{
            width: "70%",
            height: "100vh",
            padding: "1rem 1rem 1rem 0rem",
          }}
        >
          <Card css={{ width: "100%", height: "100%", padding: "1rem" }}>
            <TipsAndNotesTabs
              idClient={idClient}
              idSeller={idSeller}
            ></TipsAndNotesTabs>
            <StartTheAppointment
              setopenModalStart={setopenModalStart}
              setModalNotIntroduces={setModalNotIntroduces}
            ></StartTheAppointment>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Appointment;
