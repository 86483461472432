import { Button } from "@nextui-org/react";

import { useEffect, useState } from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { handlePrint } from "../../Utils/printUtils";
import { getBudget } from "../../Service/EmailService/EmailService";
import { useParams } from "react-router-dom";

const PrintDocuments = (props: any) => {
  const { id_appoiment }: any = useParams();
  const { planosFavoritos, documentacion, loading } = props;

  const [planosImprimir, setPlanosImprimir] = useState<any>([]);
  const [budget, setBudget] = useState<any>(undefined);
  const [loadingBudget, setLoadingBudget] = useState<boolean>(true);

  const obtenerURLs = (arrayObjetos: any) => {
    const urls: any = [];

    arrayObjetos.forEach((objeto: any) => {
      if (objeto.hasOwnProperty("url")) {
        urls.push(objeto.url);
      }
    });

    return urls;
  };

  const fetch = async () => {
    try {
      setLoadingBudget(true);
      const res = await getBudget(id_appoiment, planosFavoritos);
      setBudget(res);
    } catch (err) {
      console.log(err);
      setBudget(undefined);
    } finally {
      setLoadingBudget(false);
    }
    let planos: any = obtenerURLs(planosFavoritos);
    setPlanosImprimir(planos);
  };

  useEffect(() => {
    fetch();
  }, [planosFavoritos]);

  return loading || loadingBudget ? (
    <Button disabled>&nbsp;Obteniendo planos y documentación</Button>
  ) : (
    <Button
      onClick={() => {
        documentacion?.dossier_calidades &&
          planosImprimir.push(documentacion.dossier_calidades);
        documentacion?.dossier_comercial &&
          planosImprimir.push(documentacion.dossier_comercial);
        // documentacion?.planos_garajes &&
        //   planosImprimir.push(documentacion.planos_garajes);
        // documentacion?.planos_trasteros &&
        //   planosImprimir.push(documentacion.planos_trasteros);

        handlePrint(planosImprimir, budget);
      }}
    >
      <AiOutlinePrinter size={18} color={"white"} />
      &nbsp;Descargar planos ({planosFavoritos ? planosFavoritos.length : "0"})
      y documentación para imprimir
    </Button>
  );
};

export default PrintDocuments;
