import { Card } from "@nextui-org/react";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { TIMEZONES } from "../Constants/constants";

function DateComponent(props: any) {
  const history = useHistory();
  const [timezone] = useState<string>(
    JSON.parse(localStorage.getItem("timezone") ?? "null") || TIMEZONES[0]
  );

  const fechaString = useMemo(() => {
    const date = moment.utc(props.date);
    date.tz("Europe/Madrid");
    return date.format("HH:mm");
  }, [props.date]); // No hay que restar una hora

  return (
    <>
      {timezone && (
        <Card css={{ display: "flex", cursor: "pointer", minHeight: "125px" }}>
          <div
            style={{ padding: "5px", display: "flex", height: "125px" }}
            onClick={() => {
              if (props.RGPD) {
                history.push("/appointment/" + props.id);
              } else {
                props.setModalRGPD(true);
              }
            }}
          >
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid #ddd",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-title",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  {fechaString}
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-body",
                    fontSize: "12px",
                  }}
                >
                  {moment.utc(props.date).format("DD-MM-YYYY")}
                </h1>
              </div>
            </div>
            <div
              style={{
                width: "70%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginLeft: "5px",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  flexDirection: "column",
                }}
              >
                <h1
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-title",
                    fontSize: "15px",
                    fontWeight: "bold",
                    margin: "0px",
                    color: "var(--secondary-color)",
                  }}
                >
                  Promoción
                </h1>
                <h2
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-body",
                    fontSize: "15px",
                  }}
                >
                  {props.promotion}
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start",
                    flexDirection: "column",
                  }}
                >
                  <h1
                    style={{
                      textAlign: "start",
                      fontFamily: "lis-title",
                      fontSize: "15px",
                      margin: "0px",
                      fontWeight: "bold",

                      color: "var(--secondary-color)",
                    }}
                  >
                    Visita con
                  </h1>
                  <h2
                    style={{
                      textAlign: "start",
                      fontFamily: "lis-body",
                      fontSize: "19px",
                    }}
                  >
                    {props.client}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
}

export default DateComponent;
