import {
  Button,
  Card,
  Checkbox,
  Input,
  Loading,
  Modal,
} from "@nextui-org/react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { FiFlag } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { setDurationByAppointmentId } from "../../Service/AppointmentDuration/AppointmentDuration";
import { arquetipo } from "../../Utils/MultiSelectLists";
import MultipleSelect from "../MultipleSelect/MultipleSelect";
import SliderCustom from "../Slider/Slider";

import { useEffect, useState } from "react";
import { MdErrorOutline } from "react-icons/md";
import { sendDocumentation } from "../../Service/EmailService/EmailService";
import PrintDocuments from "../PrintDocuments/PrintDocuments";
import SummaryHouse from "./SummaryHouse";
import SelectRelationalStyle from "../Seller/SelectRelationalStyle/SelectRelationalStyle";

export default function ThirdPartAppoinmentEndModal(props: any) {
  const {
    mainImage,
    promotion,
    client,
    formik,
    seconds,
    minutes,
    hours,
    formikTask,
    sendJson,
    planosFavoritos,
    loadingPlanos,
    documentos,
    setSelectedStep,
    informacionViviendas,
    setTipRelationalStyle,
  } = props;

  const { id_appoiment }: any = useParams();

  const [sendEmail, setSendEmail] = useState(false);
  const [newEmail, setNewEmail] = useState(client.email);
  const [error, setError] = useState(false);

  const [planosAEnviar, setPlanosAEnviar] = useState(planosFavoritos);
  const [pointHouse, setPointHouse] = useState();
  const [canFinishVisit, setCanFinishVisit] = useState(true);

  const [sendingData, setSendingData] = useState(false);

  useEffect(() => {
    // Si quieren enviar el correo, no puede haber error
    sendEmail && error && setCanFinishVisit(false);
    sendEmail && !error && setCanFinishVisit(true);
    //Si no quieren enviar el correo les dejaremos siempre finalizar la visita
    !sendEmail && setCanFinishVisit(true);
  }, [sendEmail, error]);

  const history = useHistory();

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
  ];

  const handleChangeEmail = (event: any) => {
    const { value } = event.target;
    setNewEmail(value);

    // Validar el formato del correo electrónico usando una expresión regular
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setError(!emailRegex.test(value));
  };

  const handleSendingData = () => {
    setSendingData(true);
    sendJson({
      event: "gracias_por_tu_visita",
      value: "gracias_por_tu_visita",
    });

    setDurationByAppointmentId(
      id_appoiment,
      `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }:${seconds < 10 ? "0" + seconds : seconds}`,
      true,
      "",
      formikTask.values.contactoagendado,
      formikTask.values.emailenviado,
      formikTask.values.informacionsolicitada
    );

    formik.submitForm().then(() => {
      //@ts-ignore
      if (formik.values.send_email) {
        // Cuando estamos en PROD llega al cliente
        if (process.env.REACT_APP_IS_PRO === "true") {
          sendDocumentation(id_appoiment, planosAEnviar, newEmail);
        } else {
          // Si está en DEV/UAT que llegua siempre a la cuenta de Jorge "jlvalero@aedashomes.com"
          sendDocumentation(
            id_appoiment,
            planosAEnviar,
            process.env.REACT_APP_EMAIL_ADMIN
          );
        }
      }

      setSendingData(false);

      sessionStorage.removeItem("stepper_aedas");
      history.push("/");
    });
  };

  if (sendingData) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          height: "100%",
          minHeight: "350px",
        }}
      >
        <span style={{ fontSize: "1.5rem", fontFamily: "lis-title" }}>
          Guardando los datos de la visita
        </span>{" "}
        <Loading type='points' size='xl'></Loading>
      </div>
    );
  }

  return (
    <>
      <Modal.Body css={{ padding: "2rem" }}>
        <h1
          style={{
            fontFamily: "lis-body",
            fontSize: "25px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Resumen de la cita
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            variant='bordered'
            style={{
              display: "flex",
              flexDirection: "row",
              width: "650px",
              padding: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "25%",
              }}
            >
              <Card
                style={{
                  height: "4rem",
                  width: "4rem",
                  background: "var(--lis-ligthblue)",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "var(--secondary-color)",
                }}
              >
                {client.displayName
                  .split(" ")
                  .slice(0, 2)
                  .map((word: any) => word[0].toUpperCase())}
              </Card>
            </div>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{}}>
                <h1
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-title",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {client.displayName}
                </h1>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1
                    style={{
                      textAlign: "start",
                      fontFamily: "lis-body",
                      fontSize: "14px",
                      display: "flex",
                      color: "grey",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <AiOutlineMail></AiOutlineMail>
                    </div>

                    {client.email}
                  </h1>
                </div>
                <h1
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-body",
                    fontSize: "14px",
                    display: "flex",
                    color: "grey",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <AiOutlinePhone size={15}></AiOutlinePhone>
                  </div>

                  {client.phone ?? "-"}
                </h1>
              </div>
            </div>
          </Card>
          <div>
            <Card variant='bordered' style={{ width: "650px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  padding: "10px",
                }}
              >
                <Card
                  variant='flat'
                  css={{ height: "6rem", width: "6rem", objectFit: "cover" }}
                >
                  <img
                    alt='Imagen piso'
                    style={{ height: "100%", objectFit: "cover" }}
                    src={mainImage}
                  ></img>
                </Card>

                <div
                  style={{
                    fontFamily: "lis-title",
                    fontWeight: "bold",
                    fontSize: "20px",
                    padding: "0.5rem",
                  }}
                >
                  <div style={{ width: "100%", marginBottom: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <CiLocationOn></CiLocationOn>
                      <div>
                        <h1
                          style={{
                            textAlign: "start",
                            fontFamily: "lis-title",
                            fontSize: "15px",
                            fontWeight: "bold",
                            margin: "0px",
                            color: "var(--secondary-color)",
                          }}
                        >
                          {promotion.name}
                        </h1>
                        <h2
                          style={{
                            textAlign: "start",
                            fontFamily: "lis-body",
                            fontSize: "15px",
                            margin: "0px",
                            marginRight: "50px",
                          }}
                        >
                          {promotion.address}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <FiFlag size={15}></FiFlag>
                      <div>
                        <h1
                          style={{
                            textAlign: "start",
                            fontFamily: "lis-title",
                            fontSize: "15px",
                            fontWeight: "bold",
                            margin: "0px",
                            color: "var(--secondary-color)",
                          }}
                        >
                          Estado
                        </h1>
                        <h2
                          style={{
                            textAlign: "start",
                            fontFamily: "lis-body",
                            fontSize: "15px",
                            margin: "0px",
                          }}
                        >
                          {promotion.status}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div>
            <Card
              variant='bordered'
              style={{ width: "650px", padding: "1rem" }}
            >
              <form onSubmit={formik.handleSubmit}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SliderCustom
                    title='Valora la cita'
                    value={
                      formik.values.valorationDegree
                        ? parseInt(formik.values.valorationDegree)
                        : 0
                    }
                    step={0}
                    max={4}
                    marks={marks}
                    id='valorationDegree'
                    onChange={(e: any) =>
                      formik.setFieldValue("valorationDegree", e.target.value)
                    }
                  ></SliderCustom>
                </div>
                {/* Comentarios informativos para nuestros yos del futuro:
                  - Lo que antes era arquetipo y tiene las opciones de: Agua, Aire, Tierra y Fuego ahora se denomina "Estilo relacional"
                  - Por supuesto, lo que era antes el estilo relacional (el desplegable) ahora se denomina arquetipo (sigue siendo el desplegable).  
                */}
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column wrap",
                    alignItems: "center",
                  }}
                >
                  <MultipleSelect
                    values={formik.values.Arquetipo__c}
                    onChange={(e: any) => {
                      formik.setFieldValue("Arquetipo__c", e);
                    }}
                    title={"Estilo relacional"}
                    list={arquetipo}
                  ></MultipleSelect>

                  <div
                    className='relational-style'
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                  >
                    <SelectRelationalStyle
                      formikOptions={formik}
                      setTipRelationalStyle={setTipRelationalStyle}
                    />
                  </div>
                </div>
                <div className='mt-4' style={{ marginLeft: "5px" }}>
                  <Checkbox
                    onChange={(value) => {
                      formik.setFieldValue("send_email", value);
                      setSendEmail(!sendEmail);
                    }}
                  >
                    <span style={{ fontFamily: "lis-body", fontSize: "15px" }}>
                      Mandar email con documentación a <b>{client.email}</b>
                    </span>
                  </Checkbox>
                </div>
                {sendEmail && (
                  <>
                    <div
                      style={{ fontFamily: "lis-body", fontSize: "15px" }}
                      className='d-flex flex-column mt-2'
                    >
                      {/* Si NO ES PRODUCCIÓN mostramos el mensaje */}
                      {process.env.REACT_APP_IS_PRO !== "true" && (
                        <p>
                          <MdErrorOutline size={24} color={"orange"} /> Esta web
                          es de PRUEBAS,
                          <b> el correo se enviará a jlvalero@aedashomes.com</b>
                        </p>
                      )}
                      <div className='mb-2'>
                        <p
                          className='mb-1'
                          style={{ fontFamily: "lis-body", fontSize: "16px" }}
                        >
                          ¿El correo electrónico no es correcto? Puedes
                          introducir debajo el nuevo email.
                        </p>
                        Si el email está bien no hace falta que hagas nada.
                      </div>
                      <Input onChange={handleChangeEmail} value={newEmail} />
                      {error ? (
                        <p className='text-danger mt-2 mb-0'>
                          El email introducido <b>no es correcto</b>, no podrás
                          terminar la visita
                        </p>
                      ) : (
                        <p className='text-success mt-2 mb-0'>
                          El email parace ser correcto, corrobora que lo has
                          escrito bien.
                        </p>
                      )}
                    </div>

                    <div>
                      <p
                        style={{ color: "var(--secondary-color)" }}
                        className='mt-2 mb-1'
                      >
                        <b>
                          Selecciona las viviendas a enviar la documentación (1
                          email por vivienda):
                        </b>
                      </p>
                      <div className='d-flex'>
                        {planosFavoritos.length > 0 ? (
                          <div
                            className='d-grid gap-4'
                            style={{ gridTemplateColumns: "1fr 1fr" }}
                          >
                            {planosFavoritos.map((map: any, i: any) => {
                              let informationHouse = informacionViviendas?.find(
                                (info: any) => info.Asset_Id === map.asset_id
                              );

                              return (
                                <SummaryHouse
                                  sendJson={sendJson}
                                  key={`${i}-`}
                                  setPointHouse={setPointHouse}
                                  pointHouse={pointHouse}
                                  house={informationHouse}
                                  planosAEnviar={planosAEnviar}
                                  setPlanosAEnviar={setPlanosAEnviar}
                                  map={map}
                                />
                              );
                            })}
                          </div>
                        ) : (
                          <p className='font-family-lis d-flex align-items-center gap-1'>
                            <MdErrorOutline size={24} color={"#666"} /> Sin
                            planos favoritos
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </form>
            </Card>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-between w-100'>
          <Button
            css={{ background: "var(--blue-lis)" }}
            onClick={() => {
              setSelectedStep(1);
            }}
          >
            Volver
          </Button>

          <div className='d-flex gap-2'>
            <PrintDocuments
              planosFavoritos={planosAEnviar}
              documentos={documentos}
              loading={loadingPlanos}
            />
            <Button
              disabled={!canFinishVisit}
              css={{ background: "var(--blue-lis)" }}
              onClick={() => {
                handleSendingData();
              }}
            >
              {sendEmail
                ? "Enviar email y finalizar cita (Paso 3 de 3)"
                : "Finalizar cita (Paso 3 de 3)"}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
}
