import { Card } from "@nextui-org/react";
import "./PromotionInfo.css";
import { InfoPromotion, KpisPromotionInfo } from "./InfoPromotion";
import PromotionFeatures from "./PromotionFeatures";
import { useEffect, useState } from "react";
import { getAllAssetsByPromo } from "../../Service/AssetsService/AssetsService";
import AedasLoading from "../AedasLoading/AedasLoading";

function PromotionInfoFull(props: any) {
  const { promotion, formik, sellerWindow } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [mainImage, setMainImage] = useState();
  useEffect(() => {
    if (promotion) {
      setIsLoading(true);
      getAllAssetsByPromo(promotion?.id)
        .then((response) => {
          const mainImage = response.data?.principal[0]?.value;
          setMainImage(mainImage);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [promotion]);

  if (isLoading) return <AedasLoading></AedasLoading>;

  return (
    <Card
      css={{
        width: "100%",
        height: "100%",
        padding: "1px",
        alignItems: "center",
      }}
    >
      <div
        className="promotion-info"
        style={{
          overflow: "auto",
          padding: "10px",
          height: "100%",
        }}
      >
        <div>
          <Card>
            <InfoPromotion
              mainImage={mainImage}
              promotion={promotion}
            ></InfoPromotion>
          </Card>
        </div>
        <div
          className="promotion-cards"
          style={{
            marginTop: "10px",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <KpisPromotionInfo promotion={promotion}></KpisPromotionInfo>
          <PromotionFeatures
            promotion={promotion}
            formik={formik}
            sellerWindow={sellerWindow}
          />
        </div>
      </div>
    </Card>
  );
}

export default PromotionInfoFull;
