import { Button } from "@nextui-org/react";

const StartTheAppointment = (props: any) => {
  const { setopenModalStart, setModalNotIntroduces } = props;

  return (
    <div
      style={{
        marginTop: "1.7rem",
        display: "flex",
        justifyContent: "end",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        background: "white",
        width: "100%",
        padding: "1rem",
      }}
    >
      <Button
        css={{
          background: "var(--blue-lis)",
          marginInlineEnd: "5px",
          backgroundColor: "var(--aedas-red)",
        }}
        onClick={() => {
          setModalNotIntroduces(true);
          setopenModalStart(false);
        }}
      >
        No Presentado
      </Button>
      <Button
        css={{ background: "var(--blue-lis)" }}
        onPress={() => {
          setopenModalStart(true);
          setModalNotIntroduces(false);
        }}
      >
        Empezar visita
      </Button>
    </div>
  );
};

export default StartTheAppointment;
