import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { getDocumentsService } from "../../Service/DocumentsService/DocumentsService";
import { getHouseInfoByAssets } from "../../Service/HouseInfoByAssets/HouseInfoByAssets";
import { getLikesService } from "../../Service/LikesService/LikesService";
import FirstPartAppoinmentEndModal from "./FirstPartAppoinmentEndModal";
import SecondPartAppoinmentEndModal from "./SecondPartAppoinmentEndModal";
import ThirdPartAppoinmentEndModal from "./ThirdPartAppoinmentEndModal";

export default function SwitchModal(props: any) {
  const [loading, setLoading] = useState<any>(true);
  const [planosFavoritos, setPlanosFavoritos] = useState<any>([]);
  const [informacionViviendas, setInformacionVivendas] = useState<any>([]);
  const [documentacion, setDocumentacion] = useState<any>([]);

  const {
    mainImage,
    setselectedValue,
    setDisplayEmailPhoneClient,
    setOpenModal,
    setSelectedStep,
    selectedStep,
    sendJson,
    setTab,
    promotion,
    client,
    formik,
    seconds,
    minutes,
    hours,
    setTipArchetype,
    setTipRelationalStyle,
  } = props;

  const formikTask = useFormik({
    initialValues: {
      contactoagendado: false,
      emailenviado: false,
      informacionsolicitada: false,
    },
    validationSchema: null,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    const fetchData = async () => {
      if (client?.id && promotion?.id) {
        try {
          const documentacionResponse = await getDocumentsService(promotion.id);
          console.log(documentacionResponse.data);
          setDocumentacion(documentacionResponse.data);

          const likesResponse = await getLikesService(client.id, promotion.id);
          setPlanosFavoritos(likesResponse.data.planos);
        } catch (error) {
          console.log(
            "Hubo un error al obtener las imágenes y los planos marcados como favoritos"
          );
        } finally {
          setLoading(false);
        }
      }
    };

    if (client?.id && promotion?.id) {
      fetchData();
    }
  }, []);

  // Obtenemos la información de las casas a través del ID de los planos.
  useEffect(() => {
    const assetIds = planosFavoritos?.map((plano: any) => plano.asset_id);
    if (assetIds && assetIds.length > 0) {
      getHouseInfoByAssets(assetIds).then((response: any) => {
        setInformacionVivendas(response.data);
      });
    }
  }, [planosFavoritos]);

  if (loading) return <div className='my-4'>Cargando...</div>;

  switch (selectedStep) {
    case 0:
      return (
        <FirstPartAppoinmentEndModal
          setSelectedStep={setSelectedStep}
          setOpenModal={setOpenModal}
          formik={formik}
          promotion={promotion}
          client={client}
          sendJson={sendJson}
          planosFavoritos={planosFavoritos}
          loadingPlanos={loading}
          documentacion={documentacion}
        ></FirstPartAppoinmentEndModal>
      );
    case 1:
      return (
        <SecondPartAppoinmentEndModal
          setselectedValue={setselectedValue}
          setDisplayEmailPhoneClient={setDisplayEmailPhoneClient}
          setSelectedStep={setSelectedStep}
          setOpenModal={setOpenModal}
          formikTask={formikTask}
          promotion={promotion}
          client={client}
          sendJson={sendJson}
          planosFavoritos={planosFavoritos}
          loadingPlanos={loading}
          documentacion={documentacion}
        ></SecondPartAppoinmentEndModal>
      );
    case 2:
      return (
        <ThirdPartAppoinmentEndModal
          mainImage={mainImage}
          promotion={promotion}
          client={client}
          setSelectedStep={setSelectedStep}
          setOpenModal={setOpenModal}
          setselectedValue={setselectedValue}
          sendJson={sendJson}
          setTab={setTab}
          formik={formik}
          seconds={seconds}
          minutes={minutes}
          hours={hours}
          formikTask={formikTask}
          planosFavoritos={planosFavoritos}
          setPlanosFavoritos={setPlanosFavoritos}
          loadingPlanos={loading}
          documentacion={documentacion}
          informacionViviendas={informacionViviendas}
          setTipArchetype={setTipArchetype}
          setTipRelationalStyle={setTipRelationalStyle}
        ></ThirdPartAppoinmentEndModal>
      );
    default:
      return <></>;
  }
}
