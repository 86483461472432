import { Button, Card, Loading, Modal } from "@nextui-org/react";
import moment from "moment";
import { AiOutlineStar } from "react-icons/ai";
import { BsArrow90DegRight, BsCalendarEvent, BsPerson } from "react-icons/bs";
import { TbCrown } from "react-icons/tb";
import { useHistory } from "react-router-dom";
// @ts-ignore
import { useEffect, useRef, useState } from "react";
//@ts-ignore
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ToastContainer, toast } from "react-toastify";
import TodayAppointments from "../../Components/Home/TodayAppointments";
import Welcome from "../../Components/Home/Welcome";
import IndicatorHome from "../../Components/IndicatorHome/IndicatorHome";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import {
  getAllAppointmentsByUserId,
  getAllAppointmentsByUserIdLastWeek,
} from "../../Service/AppointmentService/AppointmentService";
import {
  getAllClientsBySeller,
  getAllKpisBySeller,
  getAllLeadsBySeller,
  getAllOportunitiesBySeller,
} from "../../Service/SellerService/SellerService";
import { getUser } from "../../Utils/commonUtils";
import { TableAccounts } from "./tables/TableAccounts";
import { TableTestAppoinment } from "./tables/TableTestAppoinment";
import { TableTestLeads } from "./tables/TableTestLeads";
import { TableTestOportunities } from "./tables/TableTestOportunities";
import QRCode from "react-qr-code";
import ControlZoneDate from "../../Components/ControlZoneDate/ControlZoneDate";
import { getReverseGeocode } from "../../Service/ControlZoneDate/ControlZoneDate";
import { ReverseGeoDecode } from "../../Interfaces/reverseGeodecode";

function HomeExample() {
  const [openModalTimeZone, setOpenModalTimeZone] = useState(false);
  const hasNotifiedNotifyMaximizePerformance = useRef(false);

  const [textLocationoWelcome, setTextLocationoWelcome] = useState(
    "Pensinsula / Baleares"
  );
  const [locationGeodecodeMapbox, setLocationGeodecodeMapbox] = useState("...");
  const notifyGoogleChrome = () =>
    toast.error(
      "La aplicación esta diseñada para utilizarse con Google Chrome. Por favor, entre desde Google Chrome.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );

  const notifyNoMoreAppointments = () =>
    toast.info("No se han encontrado citas", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyMaximizePerformance = () => {
    toast.info(
      "¡Mejora tu rendimiento! Cierra las pestañas que no estés utilizando para liberar recursos.",
      {
        position: "top-right",
        autoClose: 5500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    hasNotifiedNotifyMaximizePerformance.current = true;
  };

  const [modalRGPD, setModalRGPD] = useState(false);

  useEffect(() => {
    if (navigator.userAgent.indexOf("Chrome") === -1) {
      notifyGoogleChrome();
    }
    notifyMaximizePerformance();
  }, []);

  const [appointments, isFetchingAppointments] = useFetch(
    getAllAppointmentsByUserId,
    getUser()?.id
  );

  const [clients, isFetchingClients] = useFetch(
    getAllClientsBySeller,
    getUser()?.id
  );

  const [leads, isFetchingLeads, updateLeads, errorLeads] = useFetch(
    getAllLeadsBySeller,
    getUser()?.id
  );

  const [oportunities, isFetchingOportunities] = useFetch(
    getAllOportunitiesBySeller,
    getUser()?.id
  );

  const [kpis, isFetchingKpis, updateKpis, errorKpis] = useFetch(
    getAllKpisBySeller,
    getUser()?.id
  );
  const [appointmentsLasWeek, isFetchingAppointmentsLastWeek] = useFetch(
    getAllAppointmentsByUserIdLastWeek,
    getUser()?.id
  );
  const isLoading = useIsLoading([
    isFetchingOportunities,
    isFetchingLeads,
    isFetchingAppointments,
    isFetchingClients,
    isFetchingOportunities,
    isFetchingKpis,
    isFetchingAppointmentsLastWeek,
  ]);

  const history = useHistory();

  const getLoadStatus = () => {
    if (isFetchingLeads) {
      return <span>Cargando los Intereses </span>;
    }
    if (isFetchingAppointments) {
      return <span>Cargando las citas de hoy </span>;
    }
    if (isFetchingAppointmentsLastWeek) {
      return <span>Cargando las citas anteriores </span>;
    }
    if (isFetchingOportunities) {
      return <span>Cargando las oportunidades </span>;
    }
    if (isFetchingClients) {
      return <span>Cargando las cuentas </span>;
    }
    if (isFetchingKpis) {
      return <span>Cargando KPIs </span>;
    }

    return <span>Cargando</span>;
  };

  useEffect(() => {
    if (!isLoading) {
      const storedLocationControlTimezone = localStorage.getItem(
        "location_control_timezone"
      );
      const locationControlTimezone = storedLocationControlTimezone
        ? JSON.parse(storedLocationControlTimezone)
        : false;

      if (!locationControlTimezone) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            getReverseGeodecode(latitude, longitude);
          },
          (error) => {
            console.error("Error al obtener coordenadas:", error);
          }
        );
      }
    }
  }, [isLoading]);

  /**
   *
   * @param latitude
   * @param longitude
   */
  const getReverseGeodecode = (
    latitude: string | number,
    longitude: string | number
  ) => {
    getReverseGeocode(latitude, longitude)
      .then((data: ReverseGeoDecode) => {
        setLocationGeodecodeMapbox(
          data?.features[0]?.place_name.split(",").slice(-2).join(",").trim() ??
            ""
        );
        const isCanarias =
          data.features[0].place_name.includes("Canarias") ||
          data.features[0].place_name.includes("Las Palmas") ||
          data.features[0].place_name.includes("Santa Cruz de Tenerife");

        if (isCanarias) {
          setTextLocationoWelcome("Islas Canarias");
        }
      })
      .catch((error) => {
        console.error("Error al obtener datos de ubicación:", error);
      });
  };

  if (isLoading) {
    return (
      <div
        className='w-100 h-100 d-flex justify-content-center '
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        <Loading size='xl'></Loading>
        <div style={{ padding: "1rem" }}>
          {getLoadStatus()}
          <ThreeDots></ThreeDots>
        </div>
      </div>
    );
  }

  return (
    <>
      {errorLeads ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Card
            css={{ width: "50%", background: "#b1003b63", padding: "1rem" }}
          >
            <span
              style={{
                textAlign: "center",
                color: "var(--aedas-red)",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              ¡Ha habido un problema!
            </span>
            <span
              style={{
                textAlign: "center",
                color: "var(--aedas-red)",
              }}
            >
              Parece que el usuario con el que se ha logueado no está dado de
              alta como comercial en SalesForce. Si cree que ha sido un error
              contacte con el administrador del sitio.
            </span>
          </Card>
        </div>
      ) : (
        <>
          <ControlZoneDate
            openModalTimeZone={openModalTimeZone}
            setOpenModalTimeZone={setOpenModalTimeZone}
          />{" "}
          <Modal
            scroll
            preventClose
            width='900px'
            open={modalRGPD}
            onClose={() => setModalRGPD(false)}
            closeButton
          >
            <Modal.Header>
              <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                El cliente no tiene RGPD aprobado
              </span>
            </Modal.Header>
            <Modal.Body>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
              >
                El cliente debe aceptar la politica de RGPD para poder hacer la
                visita en Atenea.
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
              >
                Escaneando el siguiente QR puede acceder a la firma del RGPD:
              </span>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                >
                  <QRCode value={appointments && appointments[0]?.rgdp_link} />
                </Card>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setModalRGPD(false);
                  history.push("/");
                }}
              >
                Hecho
              </Button>
            </Modal.Footer>
          </Modal>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "30%",
                height: "100vh",
                padding: "1rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  gap: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Welcome
                  setOpenModalTimeZone={setOpenModalTimeZone}
                  textLocationoWelcome={textLocationoWelcome}
                  locationGeodecodeMapbox={locationGeodecodeMapbox}
                />
                <TodayAppointments
                  appointments={appointments}
                  setModalRGPD={setModalRGPD}
                />
              </div>
            </div>
            <div
              style={{
                width: "70%",
                height: "100vh",
                padding: "1rem 1rem 1rem 0rem",
              }}
            >
              <Card
                css={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <Card
                        isHoverable={appointments?.length === 0 ? false : true}
                        css={{
                          backgroundColor: "#B17600",
                          padding: "1rem",
                          display: "flex",
                          alignItems: "center",
                          height: "150px",
                          width: "150px",
                          cursor: "not-allowed",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <BsCalendarEvent
                            size={80}
                            color={"white"}
                          ></BsCalendarEvent>
                          <h1
                            style={{
                              color: "white",
                              fontSize: "14px",
                              paddingTop: "10px",
                            }}
                          >
                            Nueva cita
                          </h1>
                        </div>
                      </Card>

                      <Card
                        isHoverable={appointments?.length === 0 ? false : true}
                        css={{
                          backgroundColor:
                            appointments?.length === 0
                              ? "grey"
                              : "var(--secondary-color)",
                          padding: "1rem",
                          display: "flex",
                          alignItems: "center",
                          height: "150px",
                          width: "150px",
                          cursor:
                            appointments?.length === 0
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        <div
                          onClick={() => {
                            if (appointments?.length > 1) {
                              let orderedAppointments = appointments?.sort(
                                (a: any, b: any) => {
                                  if (
                                    moment(a.dateInitial).isAfter(
                                      moment(b.dateInitial)
                                    )
                                  ) {
                                    return 1;
                                  } else if (
                                    moment(a.dateInitial).isBefore(
                                      moment(b.dateInitial)
                                    )
                                  ) {
                                    return -1;
                                  }
                                  return 0;
                                }
                              );

                              let salesforceId = orderedAppointments.find(
                                (a: any) =>
                                  moment(a.dateInitial).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  ) >
                                  moment()
                                    .subtract(30, "seconds")
                                    .format("DD-MM-YYYY HH:mm:ss")
                              ).id;

                              if (salesforceId) {
                                history.push("/appointment/" + salesforceId);
                              } else {
                                notifyNoMoreAppointments();
                              }
                            } else {
                              notifyNoMoreAppointments();
                            }
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <BsArrow90DegRight
                            size={80}
                            color={"white"}
                          ></BsArrow90DegRight>
                          <h1
                            style={{
                              color: "white",
                              fontSize: "14px",
                              paddingTop: "10px",
                            }}
                          >
                            Siguiente Cita
                          </h1>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                    padding: "1rem",
                  }}
                >
                  {" "}
                  <IndicatorHome
                    title={"Intereses"}
                    value={kpis[1]?.number}
                    icon={
                      <AiOutlineStar
                        size={37}
                        color={"var(--secondary-color)"}
                      ></AiOutlineStar>
                    }
                  ></IndicatorHome>
                  <IndicatorHome
                    title={"Cuentas"}
                    value={kpis[2]?.number}
                    icon={
                      <BsPerson
                        size={37}
                        color={"var(--secondary-color)"}
                      ></BsPerson>
                    }
                  ></IndicatorHome>
                  <IndicatorHome
                    title={"Oportunidades"}
                    value={kpis[0]?.number}
                    icon={
                      <TbCrown
                        size={37}
                        color={"var(--secondary-color)"}
                      ></TbCrown>
                    }
                  ></IndicatorHome>
                </div>

                <Tabs>
                  <TabList>
                    <Tab>Intereses</Tab>
                    <Tab>Cuentas</Tab>
                    <Tab>Oportunidades</Tab>
                    <Tab>Citas</Tab>
                  </TabList>
                  <TabPanel>
                    <div
                      style={{ padding: "10px", height: "calc(100% - 8px)" }}
                    >
                      <TableTestLeads row={leads}></TableTestLeads>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      style={{ padding: "10px", height: "calc(100% - 8px)" }}
                    >
                      <TableAccounts row={clients}></TableAccounts>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      style={{ padding: "10px", height: "calc(100% - 8px)" }}
                    >
                      <TableTestOportunities
                        row={oportunities}
                      ></TableTestOportunities>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div
                      style={{ padding: "10px", height: "calc(100% - 8px)" }}
                    >
                      <TableTestAppoinment
                        row={appointmentsLasWeek}
                      ></TableTestAppoinment>
                    </div>
                  </TabPanel>
                </Tabs>
              </Card>
            </div>
          </div>
        </>
      )}

      <ToastContainer />
    </>
  );
}

export default HomeExample;

const ThreeDots = () => {
  const [dots, setDots] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((count) => (count < 3 ? count + 1 : 0));
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const baseDots = "...";

  // concatenar la cadena base con los puntos rellenos
  const filledDots = baseDots.slice(0, count);

  return <span>{filledDots}</span>;
};
