import { Card } from "@nextui-org/react";

function IndicatorHome(props: any) {
  return (
    <Card variant="bordered" css={{ width: "200px" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
            paddingLeft: "10px",
          }}
        >
          <h1
            style={{
              fontFamily: "lis-title",
              fontSize: "15px",
              marginBottom: "-5px",
              marginTop: "5px",
            }}
          >
            {props.title}
          </h1>
          <p
            style={{
              fontFamily: "lis-title",
              fontWeight: "bold",
              fontSize: "20px",
              margin: "0px",
            }}
          >
            {props.value}
          </p>
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              width: "2.5rem",
              height: "2.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "var(--lis-ligthblue)",
            }}
          >
            {props.icon}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default IndicatorHome;
