import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Collapse, Input, Loading } from "@nextui-org/react";
import debounce from "just-debounce-it";
import { useCallback, useEffect, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { GrContactInfo } from "react-icons/gr";
import { MdError } from "react-icons/md";
import { checkPostalCode } from "../../../Service/PostalCodeService/PostalCodeService";
import { como_c, paises } from "../../../Utils/MultiSelectLists";
import { sayToFormikDataHasChanged } from "../../../Utils/sellerUtils";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import {
  DataBudgetForm,
  dataIncomingMoney,
} from "../FormPayment/data/dataBudgetForm";
import { countries } from "../../../Utils/countrys";

export default function GeneralInformation(props: any) {
  const { formik } = props;
  const [loadingCheckPostalCode, setloadingCheckPostalCode] = useState(true);
  //const [errorCheckCode, setErrorCheckCode] = useState(false);
  const [postalCode, setPostalCode] = useState<any>(null);
  const [otherCountris, setOtherCountries] = useState<boolean>(
    !["Spain", "", null].includes(formik.values.Pais_residencia__pc)
  );
  const [minIncomingMoney, setMinIncomingMoney] = useState(
    formik.values?.Ingresos_Anuales_Aprox_Familiares__pc[0]
      ? formik.values.Ingresos_Anuales_Aprox_Familiares__pc[0]
      : 0
  );

  const [maxIncomingMoney, setMaxIncomingMoney] = useState(
    formik.values?.Ingresos_Anuales_Aprox_Familiares__pc[1]
      ? formik.values.Ingresos_Anuales_Aprox_Familiares__pc[1]
      : 3000000
  );
  const handleChangeMinIncomingMoney = (event: any) => {
    setMinIncomingMoney(event.target.value);
    formik.setFieldValue("Ingresos_Anuales_Aprox_Familiares__pc", [
      event.target.value,
      maxIncomingMoney,
    ]);
  };

  const handleChangeMaxIncomingMoney = (event: any) => {
    setMaxIncomingMoney(event.target.value);
    formik.setFieldValue("Ingresos_Anuales_Aprox_Familiares__pc", [
      minIncomingMoney,
      event.target.value,
    ]);
  };

  useEffect(() => {
    if (loadingCheckPostalCode) {
      checkPostalCode(formik.values.Codigo_Postal_Actual__pc)
        .then((response: any) => {
          if (response.data.code === 500) {
            setPostalCode(null);
            setloadingCheckPostalCode(false);
          } else {
            setPostalCode(response.data);
            setloadingCheckPostalCode(false);
          }
        })
        .catch(() => {
          setPostalCode(null);
          setloadingCheckPostalCode(false);
        });
    }
  }, [loadingCheckPostalCode]);

  const debouncedSubmit = useCallback(
    debounce(
      () => {
        setloadingCheckPostalCode(true);
      },

      5000
    ),
    //[formik.submitForm]
    []
  );

  useEffect(() => {
    if (checkPostalCodeSpain()) {
      debouncedSubmit();
    }
  }, [formik.values.Codigo_Postal_Actual__pc]);

  // No tiene sentido, si lo quieren se añade, pero fijo que no miran que se borra el cp con el pais y da poblemas a futuro
  // useEffect(() => {
  //   if (formik.values.Pais_residencia__pc) {
  //     formik.setFieldValue("Codigo_Postal_Actual__pc", null);
  //   }
  // }, [formik.values.Pais_residencia__pc]);

  // Enviamos array con los valores por defecto
  useEffect(() => {
    formik.setFieldValue("Ingresos_Anuales_Aprox_Familiares__pc", [
      minIncomingMoney,
      maxIncomingMoney,
    ]);
  }, []);

  const checkPostalCodeSpain = () => {
    return (
      (formik.values.Pais_residencia__pc === "España" ||
        formik.values.Pais_residencia__pc === "Spain") &&
      formik.values.Pais_residencia__pc !== null
    );
  };

  return (
    <Collapse title='Inf. General' contentLeft={<GrContactInfo size={30} />}>
      <div
        style={{
          padding: "0px 15px 15px 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "10px",
        }}
      >
        <MultipleSelect
          values={formik.values.Como__c}
          onChange={(e: any) => {
            formik.setFieldValue("Como__c", e);
          }}
          title={`¿Conocías AEDAS Homes con anterioridad?`}
          displayedInClientView={true}
          list={como_c}
        ></MultipleSelect>
        <MultipleSelect
          values={otherCountris ? "Resto de paises" : "España"}
          onChange={(e: any) => {
            if (e === "España") {
              formik.setFieldValue("Pais_residencia__pc", "Spain");
            }
            setOtherCountries(e !== "España");
          }}
          setOtherCountries={setOtherCountries}
          showCountrys={true}
          otherCountris={otherCountris}
          title={`¿En qué país reside?`}
          displayedInClientView={false}
          list={paises}
          formik={formik}
        ></MultipleSelect>
        {otherCountris && (
          <>
            <div className=''>Selecciona el país</div>
            <Autocomplete
              options={countries}
              style={{ width: "100%" }}
              value={
                formik.values.Pais_residencia__pc === "Spain"
                  ? ""
                  : formik.values.Pais_residencia__pc
              }
              onChange={(_, value) => {
                formik.setFieldValue("Pais_residencia__pc", value);
              }}
              renderInput={(params: any) => (
                <TextField {...params} label='Otros Países' />
              )}
            />
          </>
        )}
        {formik.values.Pais_residencia__pc &&
          (formik.values.Pais_residencia__pc === "España" ||
            formik.values.Pais_residencia__pc === "Spain") && (
            <Input
              css={{ width: "100%", fontSize: "17px", fontWeight: "bold" }}
              id='Codigo_Postal_Actual__pc'
              className='codigo-postal-seller-tsx'
              pattern='[0-9]*'
              maxLength={5}
              minLength={5}
              onError={(error) => console.log({ error })}
              onChange={formik.handleChange}
              helperColor={postalCode && postalCode[0] ? "default" : "primary"}
              helperText={
                postalCode && postalCode[0]
                  ? `${postalCode[0]?.poblacion},${postalCode[0]?.ccaa}`
                  : "El código postal debe tener 5 caracteres"
              }
              label='¿Cuál es tu código postal actual? *'
              placeholder='¿Cuál es tu código postal actual?'
              initialValue={formik.values.Codigo_Postal_Actual__pc}
              value={formik.values.Codigo_Postal_Actual__pc}
              contentRight={
                loadingCheckPostalCode ? (
                  <Loading size='xs' />
                ) : (
                  <>
                    {postalCode && postalCode[0] ? (
                      <AiFillCheckCircle
                        style={{ fontSize: "13px" }}
                        color='var(--aedas-green)'
                      ></AiFillCheckCircle>
                    ) : (
                      <MdError
                        style={{ fontSize: "13px" }}
                        color='var(--aedas-red)'
                      ></MdError>
                    )}
                  </>
                )
              }
            />
          )}
        <div
          className='d-flex align-items-center w-100'
          style={{ gap: "25px", paddingTop: "10px" }}
        >
          <div className='mb-2'>
            ¿Cuáles son los ingresos familiares brutos anuales?
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginRight: "20px",
            width: "100%",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id='label-min-incoming-money'>Desde</InputLabel>
            <Select
              onChange={handleChangeMinIncomingMoney}
              labelId='label-min-incoming-money'
              value={minIncomingMoney}
              label='Desde'
            >
              {dataIncomingMoney
                .filter(
                  (data: DataBudgetForm) => data.value <= maxIncomingMoney
                )
                .map((data: DataBudgetForm) => (
                  <MenuItem value={data.value} key={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id='label-max-incoming-money'>Hasta</InputLabel>
            <Select
              onChange={handleChangeMaxIncomingMoney}
              labelId='label-max-incoming-money'
              value={maxIncomingMoney}
              label='Hasta'
            >
              {dataIncomingMoney
                .filter(
                  (data: DataBudgetForm) => data.value >= minIncomingMoney
                )
                .map((data: DataBudgetForm) => (
                  <MenuItem value={data.value}>{data.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ width: "100%", gap: "10px", display: "flex" }}>
          <Input
            maxLength={25}
            css={{ width: "50%", padding: "0px" }}
            clearable
            id='Profesion__c'
            onChange={(e: any) => {
              sayToFormikDataHasChanged(formik, e);
            }}
            label={`Profesión cónyuge 1`}
            placeholder='Profesión'
            initialValue={formik.values.Profesion__c}
          />
          <Input
            maxLength={25}
            css={{ width: "50%", padding: "0px" }}
            clearable
            id='profesionConyuge2'
            onChange={(e: any) => {
              sayToFormikDataHasChanged(formik, e);
            }}
            label={`Profesión cónyuge 2`}
            placeholder='Profesión'
            initialValue={formik.values.profesionConyuge2}
          />
        </div>
        <div style={{ width: "100%", gap: "10px", display: "flex" }}>
          <Input
            css={{ width: "50%", padding: "0px" }}
            clearable
            id='Empresa_cliente__pc'
            onChange={(e: any) => {
              sayToFormikDataHasChanged(formik, e);
            }}
            label={`Empresa cónyuge 1`}
            placeholder='Nombre de la empresa'
            initialValue={formik.values.Empresa_cliente__pc}
          />
          <Input
            css={{ width: "50%", padding: "0px" }}
            clearable
            id='Empresa_De_Conyugue_2__pc'
            onChange={(e: any) => {
              sayToFormikDataHasChanged(formik, e);
            }}
            label={`Empresa cónyuge 2`}
            placeholder='Nombre de la empresa'
            initialValue={formik.values.Empresa_De_Conyugue_2__pc}
          />
        </div>
      </div>
    </Collapse>
  );
}
