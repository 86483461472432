// @ts-ignore
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Calls from "../../Components/Calls/Calls";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import { getPromoByAppointmentId } from "../../Service/AppointmentService/AppointmentService";
import { getUser } from "../../Utils/commonUtils";
import AedasLoading from "../AedasLoading/AedasLoading";
import PromotionInfoFull from "../PromotionInfo/PromotionInfoFull";

const AppointmentClientTabs = (props: any) => {
  const { client, id } = props;

  const [promotion, isFetchingPromotion] = useFetch(
    getPromoByAppointmentId,
    id
  );
  const isLoading = useIsLoading([isFetchingPromotion]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <Tabs style={{ overflow: "auto" }}>
      <TabList>
        <Tab>Llamadas</Tab>
        <Tab>Promoción</Tab>
      </TabList>
      <TabPanel className='llamadas-tab'>
        <Calls idClient={client.id} idSeller={getUser().id}></Calls>
      </TabPanel>
      <TabPanel className='promocion-tab'>
        <PromotionInfoFull
          promotion={promotion}
          sellerWindow={false}
        ></PromotionInfoFull>
      </TabPanel>
    </Tabs>
  );
};

export default AppointmentClientTabs;
