import { Card } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { getAllAssetsByPromo } from "../../Service/AssetsService/AssetsService";
import "./PromotionInfo.css";
import { InfoPromotion, KpisPromotionInfo } from "./InfoPromotion";
import AedasLoading from "../AedasLoading/AedasLoading";

const PromotionInfoDetail = (props: any) => {
  const { promotion } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [mainImage, setMainImage] = useState();

  useEffect(() => {
    if (promotion) {
      setIsLoading(true);
      getAllAssetsByPromo(promotion?.id)
        .then((response) => {
          const mainImage = response.data?.principal[0]?.value;
          setMainImage(mainImage);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [promotion]);

  if (isLoading) return <AedasLoading />;
  return (
    <div
      className='promotion-info'
      style={{
        //overflow: "auto",
        padding: "10px",
        height: "100%",
      }}
    >
      <div>
        <Card>
          <InfoPromotion
            mainImage={mainImage}
            promotion={promotion}
          ></InfoPromotion>
        </Card>
      </div>
      <div
        className='promotion-cards'
        style={{
          marginTop: "10px",
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <KpisPromotionInfo promotion={promotion}></KpisPromotionInfo>
      </div>
    </div>
  );
};

export default PromotionInfoDetail;
