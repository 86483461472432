import { DataGrid, esES } from "@mui/x-data-grid";
import moment from "moment";
import { priceFormatter } from "../../../Utils/commonUtils";

export function TableTestOportunities(props: any) {
  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "createdDate",
      headerName: "Fecha creación/modificación",
      editable: false,
      flex: 1,
      valueGetter: (params: any) =>
        `${moment(params.row.createdDate).format("DD-MM-YYYY HH:mm:ss")}`,
    },
    {
      field: "displayName",
      headerName: "Nombre",
      renderCell: (params: any) => {
        return (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "left" }}
          >
            {params.row.displayName}
          </div>
        );
      },

      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "left" }}
          >
            {params.row.email}
          </div>
        );
      },
    },
    {
      field: "phone",
      headerName: "Teléfono",
      type: "number",
      flex: 1,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Cantidad",
      flex: 1,
      editable: false,
      valueGetter: (params: any) => {
        // return params.row.amount?.toFixed(2).replace(".", ",") + " €";
        return params.row.amount;
      },
    },
  ];

  return (
    <DataGrid
      rows={
        props.row
          ? props.row.map((c: any, i: number) => {
              return {
                id: i,
                displayName: c.displayName.substring(15),
                createdDate: c.createdDate,
                email: c.email,
                phone: c.phone,
                amount: priceFormatter(c.amount),
              };
            })
          : []
      }
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      disableSelectionOnClick
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
    />
  );
}
