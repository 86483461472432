import { Card } from "@nextui-org/react";

function PromotionCardInfo(props: any) {
  const { icon, title, value } = props;
  return (
    <Card
      css={{
        maxHeight: "50rem",
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        width: "48%",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          width: "2.2rem",
          height: "2.2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "var(--lis-ligthblue)",
        }}
      >
        {icon}
      </div>
      <div style={{ width: "70%" }}>
        <h1
          style={{
            textAlign: "start",
            fontFamily: "lis-title",
            fontSize: "15px",
            fontWeight: "bold",
            margin: "0px",
            color: "var(--secondary-color)",
          }}
        >
          {title}
        </h1>
        <h2
          style={{
            textAlign: "start",
            fontFamily: "lis-body",
            fontSize: "19px",
            margin: "0px",
          }}
        >
          {value}
        </h2>
      </div>
    </Card>
  );
}

export default PromotionCardInfo;
