import { Button, Modal } from "@nextui-org/react";
import { useCallback, useMemo } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiCheckCircle } from "react-icons/fi";
import PrintDocuments from "../PrintDocuments/PrintDocuments";

export default function FirstPartAppoinmentEndModal(props: any) {
  const {
    setOpenModal,
    setSelectedStep,
    sendJson,
    planosFavoritos,
    loadingPlanos,
    documentacion,
  } = props;

  let count = useMemo(() => {
    let count = 0;
    Object.entries(props.formik.values).forEach((item) => {
      if (item[1] !== null) {
        count++;
      }
    });
    return count;
  }, [props.formik.values]);

  let stepper_info = useMemo(
    () => [
      {
        id: 1,
        value: " Enseñar proyecto interior",
        key: "ubi",
      },
      {
        id: 2,
        value: " Enseñar proyecto exterior",
      },
      {
        id: 3,
        value: " Enseñar zonas comunes",
      },
      {
        id: 4,
        value: " Personalizar calidades",
      },
      {
        id: 5,
        value: " Piso piloto",
      },
    ],
    []
  );

  //@ts-ignore
  const getStatusColor = useCallback(() => {
    if ((count / Object.entries(props.formik.values).length) * 100 <= 33) {
      return "danger";
    } else if (
      (count / Object.entries(props.formik.values).length) * 100 <=
      66
    ) {
      return "warning";
    }
    return "success";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal.Body css={{ padding: "2rem" }}>
        <h1
          style={{
            fontFamily: "lis-body",
            fontSize: "25px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Calidad de la visita
        </h1>
        <h1
          style={{
            fontFamily: "lis-body",
            fontSize: "25px",
            fontWeight: "bold",
            textAlign: "center",
            margin: "0px",
          }}
        >
          {`${count}/${Object.entries(props.formik.values).length - 11}`}
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
          }}
        >
          <div>
            {count / Object.entries(props.formik.values).length - 11 === 1 ? (
              <h1
                style={{
                  fontFamily: "lis-body",
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                ¡Enhorabuena! Tienes todos los campos completados
              </h1>
            ) : (
              <h1
                style={{
                  fontFamily: "lis-body",
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Termina las siguientes fases para aumentar la calidad
              </h1>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p>Progreso de la visita:</p>
            <ProgressBar
              now={
                (count / (Object.entries(props.formik.values).length - 11)) *
                100
              }
              label={`${(
                (count / (Object.entries(props.formik.values).length - 11)) *
                100
              ).toFixed(2)}%`}
              variant={getStatusColor()}
              color="var(--aedas-red)"
            ></ProgressBar>
          </div>
          {stepper_info.map((stepper: any, i: any) => {
            if (
              JSON.parse(sessionStorage.getItem("stepper_aedas") ?? "[]").find(
                (value: any) => value === stepper.id
              )
            ) {
              return (
                <div key={i}>
                  <FiCheckCircle
                    color="var(--aedas-green)"
                    size={25}
                  ></FiCheckCircle>{" "}
                  <span style={{ fontFamily: "lis-body" }}>
                    {stepper.value}
                  </span>
                </div>
              );
            }
            return (
              <div key={i}>
                <AiOutlineCloseCircle
                  color="var(--aedas-red)"
                  size={25}
                ></AiOutlineCloseCircle>{" "}
                <span style={{ fontFamily: "lis-body" }}>{stepper.value}</span>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button
            css={{ background: "var(--blue-lis)" }}
            onClick={() => {
              setOpenModal(false);

              // Si cerramos el modal, volvemos a pintar la galería de imágenes.
              sendJson({
                event: "change_clientview",
                value: "all_img_video",
              });
            }}
          >
            Cerrar
          </Button>
          <div className="d-flex gap-2">
            <PrintDocuments
              planosFavoritos={planosFavoritos}
              documentacion={documentacion}
              loading={loadingPlanos}
            />
            <Button
              css={{ background: "var(--blue-lis)" }}
              onClick={() => {
                setSelectedStep(1);
              }}
            >
              Terminar visita (Paso 1 de 3)
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
}
